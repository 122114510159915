<template>
  <div class="authLayout">
    <div class="container">
      <p class="theme">学科教学知识测评</p>
      <div class="login">
        <el-form style="width: 370px"
                 :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item label="" prop="username">
            <el-input v-model="loginForm.username" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input v-model="loginForm.password" show-password
                      @keyup.enter.native="loginFn"
                      prefix-icon="el-icon-mobile"></el-input>
          </el-form-item>
          <el-form-item label="" prop="remember">
            <el-button type="primary" style="width: 100%; height: 40px;"
                       @click="loginFn">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <p class="version">版权所有：香港大学教育学院学科教学知识测试的研究课题组</p>
      <!-- <p class="bottom_info"><a href="https://beian.miit.gov.cn/">京ICP备2020035453号-2</a></p> -->
    </div>
  </div>
</template>

<script>
import profiles from '@/api/profiles';

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur',
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        }],
      },
    };
  },
  methods: {
    loginFn() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          profiles.login(this.loginForm).then(({ data }) => {
            this.$message.success('登录成功');
            localStorage.setItem('userInfo', JSON.stringify(data));
            this.$router.push('/welcome');
          }).catch(() => {
            this.$message.error('登录失败');
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
  .authLayout {
    height: 100%;
  }
  .container {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    padding: 120px 0 114px;
    position: relative;
    box-sizing: border-box;
    .theme {
      font-size: 36px;
      font-weight: bold;
      text-align: center
    }
    .login {
      margin: 0 auto;
      width: 370px;
    }
    .version {
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;
    }
    .bottom_info {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
    }
  }
</style>
